:root {
    background-color: var(--lwll-white)
}

a {
    color: var(--lwll-green);
    text-decoration: none;
    font-weight: 600;
}

.GpsLogNavBar {
    position: sticky;
    top: 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1.5em;
    color: var(--lwll-navy);
    background-color: var(--lwll-white);
    border-bottom: 4px solid var(--lwll-green);
    box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.GpsLogNavBar .NavTitle {
    font-family: semplicitapro, futura, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 58pt;
    margin: 0;
    letter-spacing: 0em;
}

.NavTitleLine {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 50%;
}

.GpsLogNavBar a {
    font-family: domus, arial, sans-serif;
    color: var(--lwll-navy);
    font-size: 28pt;
    font-weight: 600;
    letter-spacing: -0.03em;
}

.GpsLogNavBar a:hover {
    color: var(--lwll-green);
}

.GpsLogNavBar a:active {
    color: var(--lwll-green);
}

.GpsLogNavBar ul {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.GpsLogNavBar li {
    margin-right: 4em;
}

.LogTrackerWrapper {
    max-width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--lwll-navy);
    padding-bottom: 2em;
}

.RiverWrapper {
    max-width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    background: 
        linear-gradient(
            to bottom,  
            rgba(255,255,255,0) 2800px, 
            #61B1D5 3000px), 
        url('./images/rivercontinuum.svg');
    background-repeat: no-repeat;
    background-size: cover, 1600px;
    background-position: 0 0, right -210px;
}

.RiverRow {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10%;
    padding-bottom: 6em;
}

.RiverTitle {
    font-family: semplicitapro, futura, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 52pt;
    color: var(--lwll-navy)
}

.RiverImageTitle {
    font-family: semplicitapro, futura, sans-serif;
    font-weight: 600;
    font-size: 24pt;
    color: var(--lwll-green);
    padding-bottom: 0.5em;
}

.RiverImageCaption {
    max-width: 400px;
    font-size: 8pt;
    color: var(--lwll-navy)
}

.RiverImage {
    margin-right: 1em;
}

.RiverImage img {
    width: 400px;
    height: 400px;
    object-fit: cover;
}

.RiverText {
    font-family: domus, arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 14pt;
    width: 25em;
    color: var(--lwll-navy)
}

.GpsLog-Row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.GpsLogMapContainer {
    width: 40vw;
    height: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 8px;
    border: 4px double #f6a800;
    border-width: thick;
}

.GpsLogMapWrapper {
    position: relative;
    width: 40vw;
    height: 40vw;
}

.GpsLogMap {
    height: 100%;
    width: 100%;
}

.GpsLogSummaryContainer {
    padding: 2em 3em 0em 3em;
    display: flex;
    flex: 1;
}

.GpsLogStatsContainer {
    padding: 0em 2em;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 2em;
}

h1 {
    font-family: semplicitapro, futura, sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 52pt;
    line-height: 1.2;
    margin: 0;
}

h2 {
    font-family: domus, arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 42pt;
}

p {
    font-family: domus, arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 14pt;
    margin: 0.6em 0em;
}

.HeaderTextLabel {
    font-family: domus, arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 14pt;
    display: block;
    margin: 0;
}

.GpsLogStatContainer {
    width: 50%;
    min-height: 6em;
}

.GpsLogStat {
    font-family: semplicitapro, futura, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 24pt;
}

.GpsLogStatLabel {
    font-family: domus, arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 18pt;
}

.GpsLogStatGraphContainer {
    width: 100%;
    padding: 1em 0em 4em 0em;
}

.GpsLogStatGraphLabel {
    font-family: domus, arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 18pt;
    margin-bottom: 0.5em;
}

.GpsLogStatGraph {
    width: 100%;
    height: 300px;
}

.GpsLogMapOverlay {
    /* overlay the parent class with a 40% black background and cover the entire parent */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.GpsLogMapBanner {
    height: 35%;
    width: 100%;
    background-color: var(--lwll-red);
    pointer-events: none;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    font-family: semplicitapro, futura, sans-serif;
    font-weight: 600;
    font-size: 20pt;
    color: var(--lwll-white);
    text-align: center;
}

.GpsLogMapBanner span{
    font-size: 12pt;
    display: inline;
}

.GpsLogConclusion {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 2em 0em;
    width: 100%;
}

.GpsLogConclusionText {
    width: 60%;
    font-family: domus, arial, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 14pt;
    color: var(--lwll-navy);
}

.GpsLogConclusionText h1, .GpsLogConclusionText p {
    text-align: left;
}

.GpsLogConclusionImage {
    width: 30%;
}

.GpsLogConclusionImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media all and (min-width: 1460px) and (max-width: 1680px) {
    .RiverWrapper {
        background: 
            linear-gradient(
                to bottom,  
                rgba(255,255,255,0) 2750px, 
                #61B1D5 3000px), 
            url('./images/rivercontinuum.svg');
        background-position: 0 0, 350px 200px;
        background-repeat: no-repeat;
        background-size: cover, 1350px;
    }
    .GpsLogSummaryContainer p {
        font-family: domus, arial, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 12pt;
        margin: 0.6em 0em;
    }
    
    .GpsLogSummaryContainer h1 {
        font-family: semplicitapro, futura, sans-serif;
        font-weight: 600;
        font-style: normal;
        font-size: 46pt;
        line-height: 1.2;
        margin: 0;
    }

    .GpsLogStat {
        font-family: semplicitapro, futura, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 20pt;
    }
    
    .GpsLogStatLabel {
        font-family: domus, arial, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 14pt;
    }

    .GpsLogStatGraphLabel {
        font-family: domus, arial, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 14pt;
        margin-bottom: 0.5em;
    }
}

@media all and (min-width: 1070px) and (max-width: 1460px) {
    .NavTitleLine {
        flex-direction: column;
    }

    .GpsLogNavBar .NavTitle {
        font-size: 40pt;
    }

    .GpsLogNavBar a {
        font-size: 20pt;
    }
    
    .LogTrackerWrapper {
        flex-direction: column;
        box-sizing: border-box;
    }
    .RiverWrapper {
        background: 
            linear-gradient(
                to bottom,  
                rgba(255,255,255,0) 2550px, 
                #61B1D5 2700px), 
            url('./images/rivercontinuum.svg');
        background-position: 0 0, 150px 50px;
        background-repeat: no-repeat;
        background-size: cover, 1300px;
    }
    .RiverImage img {
        width: 350px;
        height: 350px;
    }
    .RiverImageCaption {
        max-width: 350px;
    }
    .RiverText {
        font-size: 14pt;
        width: 23em;
    }
    .GpsLogSummaryContainer {
        width: 60%;
        padding: 2em 0em;
    }
    .GpsLogStatGraphContainer {
        margin-bottom: 4em;
    }
    .GpsLogMapContainer {
        width: 60vw;
        height: 60vw;
        margin-bottom: 2em;
    }
    .GpsLogMapWrapper {
        position: relative;
        width: 60vw;
        height: 60vw;
    }
    .GpsLogConclusion {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 2em 0em;
        width: 100%;
    }
    
    .GpsLogConclusionText {
        width: 60%;
        font-family: domus, arial, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 14pt;
        color: var(--lwll-navy);
    }
    
    .GpsLogConclusionImage {
        width: 60%;
    }
    
    .GpsLogConclusionImage img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media all and (min-width: 1070px) and (max-width: 1230px) {
    .RiverWrapper {
        background: 
            linear-gradient(
                to bottom,  
                rgba(255,255,255,0) 2400px, 
                #61B1D5 2500px), 
            url('./images/rivercontinuum.svg');
        background-position: 0 0, 110px 90px;
        background-repeat: no-repeat;
        background-size: cover, 1200px;
    }
    .RiverImage img {
        width: 300px;
        height: 300px;
    }
    .RiverImageCaption {
        max-width: 300px;
    }
    .RiverText {
        font-size: 12pt;
        width: 21em;
    }
}
@media all and (max-width: 1070px) {
    .NavTitleLine {
        flex-direction: column;
    }

    .GpsLogNavBar .NavTitle {
        font-size: 36pt;
    }

    .GpsLogNavBar a {
        font-size: 16pt;
    }

    .GpsLogNavBar li {
        margin-right: 2em;
    }
    
    .LogTrackerWrapper {
        flex-direction: column;
        box-sizing: border-box;
    }
    
    .RiverWrapper {
        background: 
            linear-gradient(
                to bottom,  
                rgba(255,255,255,0) 2100px, 
                #61B1D5 2220px), 
            url('./images/rivercontinuum.svg');
        background-position: 0 0, 60px 200px;
        background-repeat: no-repeat;
        background-size: cover, 1000px;
    }
    .RiverImage img {
        width: 200px;
        height: 200px;
    }
    .RiverImageCaption {
        max-width: 200px;
    }
    .RiverText {
        font-size: 10pt;
        width: 17em;
    }
    .GpsLogSummaryContainer {
        width: 85%;
        padding: 2em 0em;
    }
    .GpsLogStatGraphContainer {
        margin-bottom: 4em;
    }
    .GpsLogMapContainer {
        aspect-ratio: 1 / 1;
        width: 93vw;
        margin-bottom: 2em;
    }
    .GpsLogMapWrapper {
        position: relative;
        height: 93vw;
        width: 93vw;
    }
    .GpsLogConclusion {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        padding: 2em 0em;
        width: 100%;
    }
    
    .GpsLogConclusionText {
        width: 80%;
        font-family: domus, arial, sans-serif;
        font-weight: 300;
        font-style: normal;
        font-size: 14pt;
        color: var(--lwll-navy);
        text-align: center;
    }

    .GpsLogConclusionText h1 {
        font-size: 40pt;
    }
    
    .GpsLogConclusionImage {
        width: 80%;
    }
    
    .GpsLogConclusionImage img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

@media all and (max-width: 800px) {
    .NavTitleLine {
        flex-direction: column;
    }

    .GpsLogNavBar .NavTitle {
        font-size: 30pt;
    }

    .GpsLogNavBar a {
        font-size: 16pt;
    }

    .GpsLogNavBar ul {
        flex-direction: column;
    }
    
    .GpsLogNavBar li {
        margin-bottom: 0.5em;
    }
}

@media all and (max-width: 500px) {
    .RiverWrapper {
        background: 
            linear-gradient(
                to bottom,  
                rgba(255,255,255,0) 2460px, 
                #61B1D5 2810px), 
            linear-gradient(
                to bottom,
                rgba(249, 248, 245, 0.4) 0px,
                rgba(249, 248, 245, 0.4) 100%),
            url('./images/rivercontinuum.svg');
        background-position: 0 0, 0 0, -490px 70px;
        background-repeat: no-repeat;
        background-size: cover, cover, 1300px;
    }
    .RiverRow {
        flex-direction: column;
        align-items: flex-start;
        padding: 1em 1em;
    }
    .RiverTitle {
        font-size: 40pt;
    }
    .RiverImage {
        width: 70%;
        height: 70%;
    }
    .RiverImage img {
        width: 100%;
        height: 100%;
    }
    .RiverImageCaption {
        max-width: 100%;
    }
    .RiverText {
        font-size: 10pt;
        width: 70%;
        padding-top: 1em;
        text-shadow: 1px 2px 2px var(--lwll-white);
    }
}

/* library styling fixes */

.recharts-wrapper {
    position: absolute !important;
}

.gm-style iframe + div { border:none!important; }
.gm-ui-hover-effect { display:none!important; }