#GpsLogJourneyMap {
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 1em 0 2em 0;
}

#LogDiagram {
    width: 50%;
    padding: 5em 0 5em 0;
    float: right;
}

#RiverFlowDiagram {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding: 1em 0 1em 0;
}

#TechDiagram {
    width: 40%;
    float: right;
}

#HoldingTheLog {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}